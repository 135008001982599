import React from "react";
import Button from "../components/Button";
import styled from "styled-components/macro";
import background from "../images/law.jpg";
import FixedBadge from "../components/FixedBadge/FixedBadge";
// import logo from "../svg/Castle.svg";
import logo from "../svg/Transparent_Logo.svg";
// import logo from "../svg/Original_Logo.svg";
// import logo from "../svg/Grayscale_Transparent.svg";

const Container = styled.div`
  /* height: 100vh; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.85)),
    url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding: 10vh 0px;
  @media (max-width: 1100px) {
    padding: 10vh 0px;
  }
`;

const CenterText = styled.div`
  text-align: center;
`;

const Image = styled.img`
  /* margin-top: 50px; */
  height: 500px;
  width: auto;
  margin-bottom: -75px;
  margin-top: -75px;
  @media (max-width: 830px) {
    height: auto;
    width: auto;
    /* padding: 10% 0; */
  }
  @media (max-width: 550px) {
    padding: 5% 0;
  }
  @media (max-width: 368px) {
    padding: 10% 0;
  }
`;

// const Title = styled.h1`
//   /* margin: 10px 20px ; */
//   /* padding-top:25vh; */
//   padding-left: 20px;
//   padding-right: 20px;
//   font-size: 4em;
//   text-transform: uppercase;
//   color: white;
//   text-align: center;
//   font-family: "Josefin";

//   @media (max-width: 1100px) {
//     /* padding-top:10vh; */
//     font-size: 4em;
//     span {
//       display: block;
//     }
//   }
//   @media (max-width: 590px) {
//     font-size: 3em;
//   }
//   @media (max-width: 400px) {
//     font-size: 2.2em;
//   }
//   @media (max-width: 300px) {
//     font-size: 2em;
//   }
// `;

// const Tagline = styled.p`
//     font-family:'Slabo';
//     font-size: 2em;
//     margin: 50px 5px;
//     color: white;
//     text-align:center;
//     display:block;
// `;
const Features = styled.p`
  font-size: 1.4em;
  margin: 0px 5px 50px 0px;
  color: white;
  text-align: center;
  display: block;
  padding: 20px;

  @media (max-width: 590px) {
    font-size: 1em;
    /* span:nth-of-type(3){
            display:block;
        } */
  }
  @media (max-width: 400px) {
    font-size: 0.9em;
  }
`;

const StyledButton = styled(Button)`
  width: 150px;
  height: 50px;
  font-size: 1.1em;
  margin-bottom: 50px;
`;
const StyledBadge = styled(Button)`
  padding: 10px 20px;
  font-size: 1.3em;
`;

const Main = () => (
  <section id="main">
    <FixedBadge>
      <a href="tel:989-718-3007">
        <StyledBadge primary squared>
          (989) 718-3007
        </StyledBadge>
      </a>
    </FixedBadge>
    <Container fluid>
      <CenterText>
        {/* <TagLine fontSize="4em" padding="10%">Law Office of Scott N. Windsor P.L.L.C.</TagLine> */}
        <Image src={logo} alt="logo" />
        {/* <Title>
          <span>Windsor Law PLLC</span>
        </Title> */}
        {/* <Tagline>Providing representation in Arenac, Iosco, Oscoda, Ogemaw, Bay, and Gladwin Counties as well as all of Michigan.  </Tagline> */}
        <Features>
          {`Divorce & Custody | Wills, Trusts, & Estate Planning | Elder Law | Civil Litigation | Bankruptcy | Criminal Defense | Auto Accidents`}
        </Features>
        {/* <StyledButton fontSize="1.1em" margin="15px 5px 0px 5px">Contact Us</StyledButton> */}
        <a href="#contact">
          <StyledButton squared primary>
            Contact Us
          </StyledButton>
        </a>
      </CenterText>
    </Container>
  </section>
);

export default Main;

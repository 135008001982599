import React from 'react';
import styled from 'styled-components/macro';

const SytledContainer = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
${props => {
    if(!props.fluid){
        return`
            @media (min-width: 576px) {
                &{
                    max-width: 540px;
                }
            }

            @media (min-width: 768px) {
                &{
                    max-width: 720px;
                }
            }

            @media (min-width: 992px) {
                &{
                    max-width: 960px;
                }
            }

            @media (min-width: 1200px) {
                &{
                    max-width: 1140px;
                }
            }
        `;
    }
}}
   
`;

const Container = ({children,...props}) => (
    <SytledContainer {...props}>{children}</SytledContainer>
)

export default Container
const Theme = {
  colors: {
    primaryButtonBackground: "#CCB100",
    primaryButtonFont: "#242424",
    primaryButtonBackgroundHover: "#8F7C00",
    anchor: "#CCB100",
    anchorHover: "#8F7C00",
  },
  fonts: {
    baseFontSize: "16px",
    primary: "ZCOOLXiaoWei",
    secondary: "Rosarivo",
    logo: "Josefin",
  },
};

export default Theme;

import React from "react";
import styled from "styled-components/macro";
import EmailForm from "../components/EmailForm";
import Container from "../components/Container";

const PaddedContainer = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 4rem;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 5vw;
`;

const FlexItem = styled.div`
  flex-grow: 1;
`;

const SectionTitle = styled.h2`
  padding-top: 1rem;
`;

const Contact = () => {
  return (
    <section id="contact">
      <PaddedContainer>
        <SectionTitle>Contact Us</SectionTitle>
        <FlexContainer>
          <FlexItem>
            <EmailForm URL="https://h7ebo1csod.execute-api.us-east-1.amazonaws.com/prod/email" />
          </FlexItem>
        </FlexContainer>
      </PaddedContainer>
    </section>
  );
};

export default Contact;

import React from "react";
import styled from "styled-components/macro";
import PIMG from "../components/PolyIMG";
import elderIMG from "../images/elder16_9.jpg";
// import estateIMG from '../images/estate16_9.jpg';
import willIMG from "../images/will16_9.jpg";
import civil16_9 from "../images/civil16_9.jpg";
import debt16_9 from "../images/debt16_9.jpg";
import gavel16_9 from "../images/gavel16_9.jpg";
import auto16_9 from "../images/auto16_9.jpg";
import custody16_9 from "../images/custody16_9.jpg";

const StyledText = styled.div`
  /* padding: 2rem; */
  flex-grow: 1;
  text-align: center;

  p {
    font-size: 1.3em;
    margin-left: 30px;
    margin-right: 30px;
  }
  h2 {
    font-size: 1.6em;
  }
  @media (min-width: 1000px) {
    p {
      font-size: 1.7em;
    }
    h2 {
      font-size: 2em;
    }
  }
  @media (min-width: 601px) and (max-width: 800px) {
    p {
      font-size: 1em;
    }
    h2 {
      font-size: 1.2em;
    }
  }
  @media (max-width: 600px) {
    order: 1;
    margin: 20px 5px;
    p {
      font-size: 0.9em;
    }
    h2 {
      font-size: 1.2em;
    }
  }
`;
const PolyIMG = styled(PIMG)`
  @media (max-width: 600px) {
    /* display:none; */
    width: 100%;
    clip-path: none;
    /* height:50%; */
  }
`;

const StyledDiv = styled.div`
  padding: 50px 0px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 38.5vw;
  @media (min-width: 1600px) {
    height: 600px;
  }
  @media (max-width: 800px) {
    height: 45vw;
  }
  @media (max-width: 600px) {
    margin: 1rem;
    padding: 0px;
    height: inherit;
    border: double ${(props) => props.theme.colors.primaryButtonBackground};
    flex-wrap: wrap;
  }
`;
const StyledHR = styled.hr`
  margin: 20px 10vw;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0)
  );
`;

const Features = () => (
  <section id="features">
    <StyledDiv>
      <PolyIMG src={custody16_9} alt="Custody_Image" type="square-top-right" />
      <StyledText>
        <h2>Divorce and Custody</h2>
        <StyledHR />
        <p>
          Windsor Law has the experience to represent you in all family law
          matters such as divorce, custody, parenting time, child support, and
          more.
        </p>
      </StyledText>
    </StyledDiv>
    <StyledDiv>
      <StyledText>
        <h2>Wills, Trusts, &amp; Estate Planning</h2>
        <StyledHR />
        <p>
          Let our firm plan your estate by drafting wills, trusts, patient
          advocate designations, and more.
        </p>
      </StyledText>
      <PolyIMG src={willIMG} alt="Will_Image" type="square-bottom-left" />
    </StyledDiv>
    {/* <StyledDiv>
            <PolyIMG src={estateIMG} type="square-top-right" />
            <StyledText>
                <h2>Estate Planning</h2>
                <StyledHR />
                <p>Estate planning doesn't begin and end with a last will and testament. </p>
            </StyledText>
        </StyledDiv> */}
    <StyledDiv>
      <PolyIMG src={elderIMG} alt="Elder_Image" type="square-top-right" />
      <StyledText>
        <h2>Elder Law</h2>
        <StyledHR />
        <p>
          Our firm helps elders retain independence and control in the face of
          the challenges of aging, be that temporary incapacity due to a medical
          condition or long term care.
        </p>
      </StyledText>
    </StyledDiv>

    <StyledDiv>
      <StyledText>
        <h2>Civil Litigation</h2>
        <StyledHR />
        <p>
          We can provide representation for civil litigation matters including
          contract disputes, business issues, probate, custody, and divorce.
        </p>
      </StyledText>
      <PolyIMG src={civil16_9} alt="Civil_Image" type="square-bottom-left" />
    </StyledDiv>

    <StyledDiv>
      <PolyIMG src={debt16_9} alt="Debt_Image" type="square-top-right" />
      <StyledText>
        <h2>Bankruptcy</h2>
        <StyledHR />
        <p>
          Our firm can provide representation to help you with debt relief by
          filing Chapter 7 bankruptcy.
        </p>
      </StyledText>
    </StyledDiv>

    <StyledDiv>
      <StyledText>
        <h2>Criminal Defense</h2>
        <StyledHR />
        <p>Our firm has the experience to fight for your rights!&#42;</p>
      </StyledText>
      <PolyIMG src={gavel16_9} alt="Gavel_Image" type="square-bottom-left" />
    </StyledDiv>

    <StyledDiv>
      <PolyIMG src={auto16_9} alt="Auto_Image" type="square-top-right" />
      <StyledText>
        <h2>Quality Referrals</h2>
        <StyledHR />
        <p>
          We can provide quality referrals for auto accidents, personal injury,
          and medical malpractice.
        </p>
      </StyledText>
    </StyledDiv>
  </section>
);

export default Features;

import React from 'react';
import styled from 'styled-components/macro';

const StyledPolygon = styled.img`
${props => {
    if(props.type === "square-top-right"){
        return `clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);`
    }
    else if(props.type === "square-top-left"){
        return `clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);`
    }
    else if(props.type === "square-bottom-left"){
        return `clip-path: polygon(25% 0, 100% 0%, 100% 100%, 0 100%);`
    }
    else if(props.type === "square-bottom-right"){
        return `clip-path: polygon(0 0, 75% 0, 100% 100%, 0 100%);`
    }
}}



height:100%;

`;

const PolyIMG = ({children,...props}) => (
    <StyledPolygon {...props}>{children}</StyledPolygon>
    )

export default PolyIMG
import React from "react";
import styled from 'styled-components/macro';

const FixedDiv = styled.div`
position: fixed;
  top: 0;
  left: 75vw;
  z-index: 999;
  width: inherit;
  height: inherit;
  @media(max-width: 950px){
        display:none;
    }

`;

const FixedBadge = ({ children, ...props }) => (
    <FixedDiv {...props}>{children}</FixedDiv>
)

export default FixedBadge
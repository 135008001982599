import React from "react";
import { ThemeProvider } from "styled-components";
import Theme from "./styles/Theme";
import GlobalStyle from "./styles/Global";
import Main from "./sections/Main";
import Footer from "./sections/Footer";
import Contact from "./sections/Contact";
import Features from "./sections/Features";
import FootNotes from "./sections/FootNotes";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <Main />
        <Features />
        <Contact />
        <FootNotes />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;

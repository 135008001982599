import React from "react";
import styled from "styled-components/macro";
import Container from "../components/Container";

const Paragraph = styled.p`
  font-size: 14px;
`;
const FootNotes = () => (
  <Container>
    <Paragraph>
      If you require any accommodations, please let us know. Our bathroom
      facilities are ADA compliant.
    </Paragraph>
    <Paragraph>
      &#42; Our firm cannot handle criminal matters in Arenac County due to
      Scott N. Windsor being employed as the Arenac County Assistant Prosecutor.
    </Paragraph>
  </Container>
);

export default FootNotes;

import React from "react";
import styled from "styled-components/macro";
import AutoCopyright from "../components/AutoCopyright";
import Container from "../components/Container";

const StyledDiv = styled.div`
  background-color: #3e3c3e;
  height: 200px;
  padding-top: 1rem;
`;
const CopyrightLogo = styled.div`
  text-align: center;
  font-size: 12px;
  color: white;
  width: 100%;
  margin-top: 30px;
  p {
    margin-bottom: 0px;
  }
  /* margin-bottom: 20px;  */
`;
const Address = styled.address`
  color: #e0e0e0;
  span {
    font-family: ${(props) => props.theme.fonts.logo};
    font-size: 1.4em;
  }
  @media (max-width: 450px) {
    span {
      font-size: 1.2em;
    }
  }
  @media (max-width: 380px) {
    font-size: 0.8em;
  }
`;
const Anchor = styled.a`
  color: ${(props) => props.theme.colors.anchor};
  &:hover {
    color: ${(props) => props.theme.colors.anchorHover};
  }
`;

const Footer = () => (
  <section id="footer">
    <StyledDiv>
      <Container>
        <Address>
          <span>WINDSOR LAW PLLC</span> <br />
          111 E. Cedar Street, PO Box 101
          <br />
          Standish, MI 48658
          <br />
          <Anchor href="tel:989-718-3007">(989) 718-3007</Anchor>
        </Address>
      </Container>
      <CopyrightLogo>
        <p>
          <AutoCopyright year={2020} text="WINDSOR LAW PLLC" />
        </p>
      </CopyrightLogo>
    </StyledDiv>
  </section>
);

export default Footer;

import React from 'react';
import styled from 'styled-components/macro';

const Input = styled.input`
   display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: ${props => props.squared ? "0px" : ".25rem"} ;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #CCB100;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(204, 177, 0, 0.25);
    }

    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    &[type=\"date\"],
    &[type=\"time\"],
    &[type=\"datetime-local\"],
    &[type=\"month\"] {
       
            appearance: none; // Fix appearance for date inputs in Safari
        
    }

    &[type=\"textarea\"]{
       height:auto;
  }
    

    &::placeholder {
        color: #6c757d;
        opacity: 1;
    }
  &:disabled,
  &[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
`;

const FormInput = ({ children, ...props }) => (
    <Input {...props}>{children}</Input>
)

export default FormInput